<script>
   import Base from '@backend/Base.vue';
   export default {
      extends: Base,
      data() {
         return {
            Name: "BoDashboard",
            site_name: "",
            resData: {},
            contact: {},
            register: {},
         }
      },
      computed: {
         ufullname() {
            return this.user ? this.user.bu_full_name : "-"
         }
      },
      mounted() {
         this.$set(this.$root, 'page', this)
         this.refreshData()
      },
      methods: {}
   };
</script>

<template>
   <div class="container-fluid">
      <PageTitle></PageTitle>
      <div class="row">
         <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="card">
               <div class="card-body">
                  <h5 class="card-title">Howdy {{ufullname}}</h5>
                  <br> Welcome to the <strong>{{site_name}}</strong> Website Administrator Page
                  <br>You are on the PROTECTED page of the <strong>{{site_name}}</strong> Website Content Management
                  system.<br><br>
                  <p> This page is specifically intended ONLY for website administrators who will manage content.<br>
                     Before making arrangements, there are few things to note: <br><br>

                     1. Save and secure your Username and Password to avoid undesirable things.<br>
                     2. Prepare the material or content that will be updated first.<br>
                     3. Also prepare photos, videos or other related material, to facilitate the updating process.<br>
                     4. If there are questions about using features in this backoffice, you can ask the web developer
                     contact below.<br>
                     5. Use the latest version of the browser to get the compatibility features in this backoffice.<br>
                     <p>
                        Web Developer Contact : lantai21<br>
                        Email : richard@lantai21.com<br>
                        Phone : 0818 900 754
                     </p><br />
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-md-6 col-lg-3">
            <div class="wrap_color_box3 pb-0 pb-lg-3">
               <div class="row">
                  <div class="col-md-12">
                     <div class="card mb-0">
                        <div class="card-body">
                           <div class="d-flex no-block flex-row">
                              <div class="round align-self-center round-warning"><i class="fas fa-cube"></i></div>
                              <div class="m-l-10 align-self-center">
                                 <h3 class="m-b-0">{{resData.ard_review}}</h3>
                                 <h5 class="text-muted m-b-0 f_14">Total Review</h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="wrap_color_box3 mb-3 mb-md-0">
               <div class="row">
                  <div class="col-md-12">
                     <div class="card mb-0">
                        <div class="card-body">
                           <div class="d-flex no-block flex-row">
                              <div class="round align-self-center round-success"><i class="fas fa-cube"></i></div>
                              <div class="m-l-10 align-self-center">
                                 <h3 class="m-b-0">{{resData.ard_avaibility}}</h3>
                                 <h5 class="text-muted m-b-0 f_14">Check Avaibility</h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="col-md-6 col-lg-9">
            <div class="wrap_color_box mb-3 mb-lg-0">
               <div class="row">
                  <div class="col-lg-4">
                     <router-link :to="{name:page.menuRole('AllProduct')?'AllProduct':''}">
                        <div class="card mb-lg-0">
                           <div class="card-body">
                              <div class="d-flex no-block flex-row">
                                 <div class="round align-self-center round-info"><i class="fas fa-wine-glass"></i></div>
                                 <div class="m-l-10 align-self-center">
                                    <h3 class="m-b-0">{{resData.ard_prod}}</h3>
                                    <h5 class="text-muted m-b-0 f_14">Products List</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </router-link>
                  </div>
                  <!-- Column -->
                  <div class="col-lg-4">
                     <router-link :to="{name:page.menuRole('AllProduct')?'AllProduct':'', query:{status:'Y'}}">
                        <div class="card mb-lg-0">
                           <div class="card-body">
                              <div class="d-flex no-block flex-row">
                                 <div class="round align-self-center round-success"><i class="fas fa-wine-glass"></i>
                                 </div>
                                 <div class="m-l-10 align-self-center">
                                    <h3 class="m-b-0">{{resData.ard_prod_active}}</h3>
                                    <h5 class="text-muted m-b-0 f_14">Products Active</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </router-link>
                  </div>
                  <!-- Column -->
                  <div class="col-lg-4">
                     <router-link :to="{name:page.menuRole('AllProduct')?'AllProduct':'', query:{status:'N'}}">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="d-flex no-block flex-row">
                                 <div class="round align-self-center round-danger"><i class="fas fa-wine-glass"></i>
                                 </div>
                                 <div class="m-l-10 align-self-center">
                                    <h3 class="m-b-0">{{resData.ard_prod_inactive}}</h3>
                                    <h5 class="text-muted m-b-0 f_14">Products Inactive </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </router-link>
                  </div>
               </div>
            </div>

            <div class="wrap_color_box2">
               <div class="row">
                  <div class="col-lg-4">
                     <router-link :to="{name:page.menuRole('AppUserRegister')?'AppUserRegister':''}">
                        <div class="card mb-lg-0">
                           <div class="card-body">
                              <div class="d-flex no-block flex-row">
                                 <div class="round align-self-center round-info"><i class="fas fa-user"></i></div>
                                 <div class="m-l-10 align-self-center">
                                    <h3 class="m-b-0">{{resData.ard_customers}}</h3>
                                    <h5 class="text-muted m-b-0 f_14">Signup Customers</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </router-link>
                  </div>
                  <!-- Column -->
                  <div class="col-lg-4">
                     <router-link :to="{name:page.menuRole('BoAffiliate')?'BoAffiliate':''}">
                        <div class="card mb-lg-0">
                           <div class="card-body">
                              <div class="d-flex no-block flex-row">
                                 <div class="round align-self-center round-danger"><i class="fas fa-handshake"></i>
                                 </div>
                                 <div class="m-l-10 align-self-center">
                                    <h3 class="m-b-0">{{resData.ard_affiliate}}</h3>
                                    <h5 class="text-muted m-b-0 f_14">Signup Affiliate</h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </router-link>
                  </div>
                  <!-- Column -->
                  <div class="col-lg-4">
                     <router-link :to="{name:page.menuRole('BoWineries')?'BoWineries':''}">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="d-flex no-block flex-row">
                                 <div class="round align-self-center round-success"><i class="fas fa-handshake"></i>
                                 </div>
                                 <div class="m-l-10 align-self-center">
                                    <h3 class="m-b-0">{{resData.ard_wineries}}</h3>
                                    <h5 class="text-muted m-b-0 f_14">Signup Wineries </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </router-link>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- ============================================================== -->
      <!-- charts -->
      <!-- ============================================================== -->

      <div class="row mt-3">
         <div class="col-12" v-if="page.menuRole('AppUserRegister')==true">
            <div class="card">
               <div class="card-body">
                  <h5 class="card-title">User Register</h5>
               </div>
               <div class="table-responsive ajax-table">
                  <table class="table table-striped table-bordered">
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>{{fields.au_full_name}}</th>
                           <th>{{fields.au_email_address}}</th>
                           <th>{{fields.au_phone}}</th>
                           <th>{{fields.au_is_active}}</th>
                        </tr>
                     </thead>
                     <tbody v-if="register.length>0">
                        <tr v-for="(v,k) in register" :key="k">
                           <td class="number">{{k+1}}</td>
                           <td>{{v.au_full_name}}</td>
                           <td>{{v.email}}</td>
                           <td>{{v.mobile}}</td>
                           <td v-if="v.au_is_active == 'N'"><label class="label label-warning">Unverified</label>
                           </td>
                           <td v-if="v.au_is_active == 'Y'"><label class="label label-success">Verified</label>
                           </td>
                        </tr>
                     </tbody>
                     <tbody v-else>
                        <tr>
                           <td colspan="9" class="text-center"><strong>No content available</strong></td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
         <div class="col-12" v-if="page.menuRole('BoContact')==true">
            <div class="card">
               <div class="card-body">
                  <h5 class="card-title">Contact Us</h5>
               </div>
                  <div class="table-responsive">
                     <table class="table table-striped table-bordered">
                        <thead>
                           <tr>
                              <th>#</th>
                              <th>Full Name</th>
                              <!-- <th>No Handphone</th> -->
                              <th>Email Address</th>
                              <th>Subject</th>
                              <th>Submit Date</th>
                           </tr>
                        </thead>
                        <tbody v-if="contact.length > 0">
                           <tr v-for="(v,k) in contact" :key="k">
                              <td class="number">{{k+1}}</td>
                              <td>{{v.af_name}}</td>
                              <!-- <td>{{v.af_phone}}</td> -->
                              <td>{{v.af_email_address}}</td>
                              <td>{{v.af_address}}</td>
                              <td>{{(v.af_submit_at).dates('format2')}}</td>
                           </tr>
                           <tr v-if="NotFound">
                              <td colspan="99">
                                 <h3 class="tc">{{NotFound}}</h3>
                              </td>
                           </tr>
                        </tbody>
                        <tbody v-else>
                           <tr>
                              <td colspan="9" class="text-center"><strong>No content available</strong></td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
            </div>
         </div>

      </div>
   </div>
</template>